import { createRoute } from '@tanstack/react-router'
import React, { useMemo } from 'react'
import { legacyHivesRootRoute } from '@/router/rootRoutes'
import { LegacyHiveClusterCard } from '@/features/LegacyHives/components/LegacyHiveClusterCard'
import { useFindHivesByOrgId } from '@/generated/http-clients/v2ApiComponents'
import { flattenOrganizationHivesResponse } from '@/features/LegacyHives/util'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { legacyHivesOrganizationDetailsRoute } from './LegacyHivesOrganizationDetails'

export const legacyHivesClusterDetailsRoute = createRoute({
  getParentRoute: () => legacyHivesRootRoute,
  path: '/orgs/$orgId/$clusterId',
  component: LegacyHivesClusterDetails,
})

export function LegacyHivesClusterDetails() {
  const pathParams = legacyHivesClusterDetailsRoute.useParams()

  const hivesRequest = useFindHivesByOrgId({
    pathParams: {
      orgId: pathParams.orgId,
    },
  })

  const flattenedData = useMemo(() => {
    if (hivesRequest.data) {
      return flattenOrganizationHivesResponse(hivesRequest.data)
    }
    return []
  }, [hivesRequest.data])

  const currentCluster = useMemo(() => {
    if (flattenedData) {
      return flattenedData.find(
        (cluster) => cluster.hive_info?.cluster_id === pathParams.clusterId
      )
    }
  }, [flattenedData])

  if (!currentCluster) {
    return <div>Cluster not found</div>
  }

  const breadCrumbs = [
    { name: 'Legacy Hives', path: legacyHivesRootRoute.to },
    {
      name: pathParams.orgId,
      path: legacyHivesOrganizationDetailsRoute.to,
      pathParams: {
        orgId: pathParams.orgId,
      },
    },
    {
      name: pathParams.clusterId,
      path: legacyHivesClusterDetailsRoute.to,
    },
  ]

  // Render the cluster card
  return (
    <PageLayout
      title={'Cluster Overview'}
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      <LegacyHiveClusterCard cluster={currentCluster} />
    </PageLayout>
  )
}
