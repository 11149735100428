import React from 'react'
import { ReactDiffEditor } from '@/components/DiffEditor/DiffEditor'
import { useGetTemplateById } from '@/generated/http-clients/v3ApiComponents'
import { templatesRootRoute } from '@/router/rootRoutes'
import { Box, Flex } from '@chakra-ui/react'
import { createRoute } from '@tanstack/react-router'
import { TemplateData } from './TemplateData'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { templateTableRoute } from '../page/TemplateTable'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'

export const dynamicTemplateComparison = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: 'compare/$templateId0/$templateId1',
  component: () => <DynamicComparison />,
})

export function DynamicComparison() {
  const { templateId0, templateId1 } = dynamicTemplateComparison.useParams()

  const template0 = useGetTemplateById({
    pathParams: { templateId: templateId0 },
  })
  const template1 = useGetTemplateById({
    pathParams: { templateId: templateId1 },
  })

  const breadCrumbs = [
    { name: 'Templates', path: templateTableRoute.to },
    { name: 'Compare Templates', path: '' },
  ]

  return (
    <PageLayout
      title={'Compare Templates'}
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      <Box as="article" width={'85%'}>
        <Flex mb={'12'} gap={'6'} pr={'10'}>
          {[
            { ...template0, id: templateId0 },
            { ...template1, id: templateId1 },
          ].map(({ data, id }) => (
            <TemplateData
              key={id}
              name={data?.name}
              description={data?.description}
              url={data?.chart_source.chart_repo_url}
              target_revision={data?.chart_source.chart_target_revision}
              path={data?.chart_source.chart_path}
              templateId={`#${id}`}
            />
          ))}
        </Flex>
        <ReactDiffEditor
          original={template0.data?.yaml}
          modified={template1.data?.yaml}
          language="yaml"
        />
      </Box>
    </PageLayout>
  )
}
