import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Skeleton,
  type StackProps,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import lowerCase from 'lodash/lowerCase'
import { CheckIcon, CopyIcon, PenIcon } from 'lucide-react'
import React, { type MouseEventHandler, useEffect } from 'react'

type DataInputProps = {
  label: string
  value?: string | number
  isLoading?: boolean
  isEditable?: boolean
  onEditClick?: MouseEventHandler<HTMLButtonElement>
} & StackProps

export function DataInput({
  label,
  value = '',
  isLoading,
  isEditable = false,
  onEditClick = () => void 0,
  ...stackProps
}: DataInputProps) {
  const { hasCopied, onCopy } = useClipboard(value as string)
  const toast = useToast()

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: `Copied ${lowerCase(label)} to clipboard`,
        status: 'success',
        duration: 2000,
      })
    }
  }, [hasCopied])

  return (
    <HStack width="100%" alignItems="end" {...stackProps}>
      <FormControl isReadOnly>
        <FormLabel>{label}</FormLabel>
        {isLoading ? (
          <Skeleton w="100%" borderRadius="md" opacity="0.3">
            <Input value={value} />
          </Skeleton>
        ) : (
          <Input value={value} />
        )}
      </FormControl>

      {isEditable ? (
        <IconButton
          icon={<Icon as={PenIcon} />}
          variant="outline"
          aria-label={`Edit ${label}`}
          size="lg"
          onClick={onEditClick}
          sx={{
            height: '38px !important',
          }}
        />
      ) : null}

      <IconButton
        icon={
          <Icon
            as={hasCopied ? CheckIcon : CopyIcon}
            color={hasCopied ? 'success.500' : 'inherit'}
          />
        }
        variant="outline"
        aria-label={`Copy ${label}`}
        size="lg"
        onClick={onCopy}
        sx={{
          height: '38px !important',
        }}
      />
    </HStack>
  )
}
