import * as React from 'react'
import { IdentitySupportProvider } from './identitySupport'
import type { PropsWithChildren } from 'react'

const providers = [IdentitySupportProvider]

export function ContextProvider({ children }: PropsWithChildren) {
  return (
    <>
      {providers.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children
      )}
    </>
  )
}
