import { ApprovalState } from '@/generated/http-clients/v3ApiSchemas'
import { Box, Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  CircleAlert,
  CircleCheck,
  FileCheck,
  FilePlus,
  FileX,
} from 'lucide-react'
import React, { PropsWithChildren } from 'react'

type RevisionStateProps = {
  approvalState: ApprovalState
  size?: 'sm' | 'md' // default size: 'sm' (1rem)
}

function hoverText(approvalState: ApprovalState, hoverText: string) {
  return (
    <Stack gap={0}>
      <Text color="white" fontWeight={'bold'}>
        {hoverText}
      </Text>
      <Text color="gray" fontSize={'xs'}>
        Approval state: {approvalState.state}
      </Text>
    </Stack>
  )
}

export function RevisionState({
  /**
   * The revision's approval state.
   */
  approvalState,
  size,
}: PropsWithChildren<RevisionStateProps>) {
  const iconSize = size === 'md' ? '1.5rem' : '1rem'
  return (
    <Box paddingY={'0.25rem'}>
      {approvalState.state === 'SUBMITTED' ? (
        <Tooltip label={hoverText(approvalState, 'Submitted')}>
          <FilePlus size={iconSize} color="gray" />
        </Tooltip>
      ) : approvalState.state === 'APPROVED' ? (
        <Tooltip label={hoverText(approvalState, 'Approved')}>
          <FileCheck size={iconSize} color="green" />
        </Tooltip>
      ) : approvalState.state === 'REJECTED' ? (
        <Tooltip label={hoverText(approvalState, 'Rejected')}>
          <FileX size={iconSize} color="red" />
        </Tooltip>
      ) : approvalState.state === 'APPLIED' ? (
        <Tooltip label={hoverText(approvalState, 'Applied')}>
          <CircleCheck size={iconSize} color="green" />
        </Tooltip>
      ) : (
        <Tooltip label={hoverText(approvalState, 'Unknown')}>
          <CircleAlert size={iconSize} color="orange" />
        </Tooltip>
      )}
    </Box>
  )
}
