import { useGetTemplateById } from '@/generated/http-clients/v3ApiComponents'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import React from 'react'
import { FormLabel, VStack } from '@chakra-ui/react'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import { DataInput } from '@/components/DataInput/DataInput'
import { EditorWrapper } from '@/features/Templates/components/EditorWrapper'
import { createRoute } from '@tanstack/react-router'
import { templatesRootRoute } from '@/router/rootRoutes'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'

export const templateDetailsRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: '$templateId',
  component: () => <TemplateDetails />,
})

export function TemplateDetails() {
  const params = templateDetailsRoute.useParams()
  const templateId = params.templateId

  if (!templateId) {
    return null
  }

  const template = useGetTemplateById({
    pathParams: {
      templateId: templateId,
    },
  })

  const title = `Template #${templateId}`
  const breadCrumbs = [
    { name: 'Templates', path: templateTableRoute.to },
    { name: title, path: '' },
  ]

  return (
    <PageLayout title={<BreadCrumbs paths={breadCrumbs} />}>
      <SectionCard title={'Details'}>
        <VStack alignItems={'start'}>
          <FormLabel fontWeight={600} fontSize={18}>
            General
          </FormLabel>
          <DataInput label={'Name'} value={template.data?.name} />
          <DataInput label={'Description'} value={template.data?.description} />
          <FormLabel mt={4} fontWeight={600} fontSize={18}>
            Chart Info
          </FormLabel>
          <DataInput
            label={'Repository URL'}
            value={template.data?.chart_source.chart_repo_url}
          />
          <DataInput
            mt={1}
            label={'Target Revision'}
            value={template.data?.chart_source.chart_target_revision}
          />
          <DataInput
            mt={1}
            label={'Chart Path'}
            value={template.data?.chart_source.chart_path}
          />
        </VStack>
      </SectionCard>
      <SectionCard title={'YAML'}>
        {/*  @ts-ignore template yaml is always there or empty */}
        <EditorWrapper readOnly={true} yamlContent={template.data?.yaml} />
      </SectionCard>
    </PageLayout>
  )
}
