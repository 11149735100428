import revisionStates from '@/features/ClusterConfigs/utils/revisionStates'
import { LatestClusterRevision } from '@/generated/http-clients/v3ApiSchemas'
import { Box, Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  Ban,
  CircleAlert,
  CircleMinus,
  FileCheck,
  FilePlus,
  FileQuestion,
  FileX,
  LoaderCircle,
  Lock,
  RefreshCw,
  RotateCcw,
} from 'lucide-react'
import React, { PropsWithChildren } from 'react'
import styles from './clusterConfigState.module.css'

/**
 * Fields needed in revision:
 * - revision (of type ClusterRevision)
 *   - approval_state.state
 *   - rollout.state
 *   - rollout.state_message
 *   - hive_state.state
 * - disabled
 */
type ClusterConfigStateProps = {
  revision: LatestClusterRevision
}

function hoverText({ revision }: LatestClusterRevision, hoverText: string) {
  return (
    <Stack gap={0}>
      <Text color="white" fontWeight={'bold'}>
        {hoverText}
      </Text>
      <Text color="gray" fontSize={'xs'}>
        Approval state: {revision.approval_state.state}
      </Text>
      <Text color="gray" fontSize={'xs'}>
        Rollout state: {revision.rollout?.state || 'undefined'}
      </Text>
      <Text color="gray" fontSize={'xs'}>
        Hive state: {revision.hive_state?.state || 'undefined'}
      </Text>
      {revision.rollout?.state_message ? (
        <Text color="gray" fontSize={'xs'}>
          State message: {revision.rollout?.state_message}
        </Text>
      ) : null}
    </Stack>
  )
}

export function ClusterConfigCombinedStates({
  /**
   * The revision from which we need to interpret states.
   */
  revision,
}: PropsWithChildren<ClusterConfigStateProps>) {
  return (
    <Box paddingY={'0.25rem'}>
      {revisionStates.isDisabled(revision) ? (
        <Tooltip label={hoverText(revision, 'Disabled')}>
          <CircleMinus size={'1rem'} color="gray" />
        </Tooltip>
      ) : revisionStates.hasAProblem(revision) ? (
        <Tooltip label={hoverText(revision, 'Has a problem')}>
          <CircleAlert size={'1rem'} color="red" />
        </Tooltip>
      ) : revisionStates.isBlocked(revision) ? (
        <Tooltip label={hoverText(revision, 'Blocked')}>
          <Ban size={'1rem'} color="red" />
        </Tooltip>
      ) : revisionStates.isDeleting(revision) ? (
        <Tooltip label={hoverText(revision, 'Deleting...')}>
          <RotateCcw
            size={'1rem'}
            color="blue"
            className={styles.rotatingCcw}
          />
        </Tooltip>
      ) : revisionStates.isLocked(revision) ? (
        <Tooltip label={hoverText(revision, 'Locked')}>
          <Lock size={'1rem'} color="red" />
        </Tooltip>
      ) : revisionStates.isSubmitted(revision) ? (
        <Tooltip label={hoverText(revision, 'Submitted')}>
          <FilePlus size={'1rem'} color="gray" />
        </Tooltip>
      ) : revisionStates.isApproved(revision) ? (
        <Tooltip label={hoverText(revision, 'Approved')}>
          <FileCheck size={'1rem'} color="green" />
        </Tooltip>
      ) : revisionStates.isRejected(revision) ? (
        <Tooltip label={hoverText(revision, 'Rejected')}>
          <FileX size={'1rem'} color="red" />
        </Tooltip>
      ) : revisionStates.isUnknown(revision) ? (
        <Tooltip label={hoverText(revision, 'Unknown')}>
          <FileQuestion size={'1rem'} color="orange" />
        </Tooltip>
      ) : revisionStates.isRunning(revision) ? (
        <Tooltip label={hoverText(revision, 'Running')}>
          <div className={styles.circleLoader}>
            <div className={styles.circle}></div>
            <div className={styles.expandingLine}></div>
          </div>
        </Tooltip>
      ) : revisionStates.isUpdating(revision) ? (
        <Tooltip label={hoverText(revision, 'Updating')}>
          <div className={styles.circleLoader}>
            <LoaderCircle
              size={'1rem'}
              color="green"
              className={styles.rotatingCw}
            />
            <div className={styles.smallCircle}></div>
            <div className={styles.expandingLine}></div>
          </div>
        </Tooltip>
      ) : revisionStates.isRestarting(revision) ? (
        <Tooltip
          label={hoverText(
            revision,
            'Broker restarting or starting for the first time'
          )}
        >
          <div className={styles.circleLoader}>
            <RefreshCw
              size={'1rem'}
              color="blue"
              className={styles.rotatingCw}
            />
          </div>
        </Tooltip>
      ) : (
        // By default, display a warning
        <Tooltip label={hoverText(revision, 'Unknown')}>
          <CircleAlert size={'1rem'} color="orange" />
        </Tooltip>
      )}
    </Box>
  )
}
