export function useHandleKeyDown(
  buttonRef: React.RefObject<HTMLButtonElement>
) {
  return (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if Command (Mac) or Ctrl (Windows) + Enter is pressed
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault() // Prevent the default Enter behavior (next line)

      if (buttonRef.current) {
        buttonRef.current.focus() // Move focus to the submit button

        // Add a small delay to show the active state
        setTimeout(() => {
          buttonRef.current!.click() // Simulate a click on the submit button
        }, 200)
      }
    }
  }
}
