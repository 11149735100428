import styles from '../style/IdentitySupport.module.css'
import * as React from 'react'
import {
  ACTIVATED,
  IdentityAction,
  IdentitySupportContext,
} from '@/stores/identitySupport'
import { UserIdentity } from '@/@types/identity'
import classnames from 'classnames'
import { Status } from '@/@types'
import { createRoute } from '@tanstack/react-router'
import { applicationRootRoute } from '@/router/rootRoutes'
import { Button, Input, Text } from '@chakra-ui/react'

const EMAIL_ERROR = 'Please provide a valid email address'

enum AuthMethod {
  UsernameAndPassword = 'Username and Password',
  Google = 'Google',
  Github = 'Github',
  LinkedIn = 'LinkedIn',
}

export const identitySupportRoute = createRoute({
  getParentRoute: () => applicationRootRoute,
  path: 'identity',
  component: () => <IdentitySupport />,
})

function IdentitySupport() {
  const { activateUser, getUserByEmail, resendVerification, storeState } =
    React.useContext(IdentitySupportContext)

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const [state, setState] = React.useState({ email: '', error: '' })

  React.useEffect(() => {
    const emailIsValid = emailRegex.test(state.email)

    if (emailIsValid) {
      getUserByEmail(state.email)
      setState((state) => ({ ...state, error: '' }))
    }

    if (state.email.length && !emailIsValid) {
      setState((state) => ({ ...state, error: EMAIL_ERROR }))
    }
  }, [state.email])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setState((state) => ({ ...state, email: value }))
  }

  type OrgProperty = keyof UserIdentity['orgs'][0]

  const keyToDisplay: Record<OrgProperty, string> = {
    org_id: 'Organization ID',
    org_name: 'Organization Name',
    owner_id: 'Owner Id',
  }

  const emailIsSame = storeState.email === state.email

  function authMethod(userId: string) {
    const id = userId.toLowerCase()

    if (id.startsWith('google')) {
      return AuthMethod.Google
    }
    if (id.startsWith('github')) {
      return AuthMethod.Github
    }
    if (id.startsWith('linked')) {
      return AuthMethod.LinkedIn
    }
    return AuthMethod.UsernameAndPassword
  }

  function getLastActionFor(userId: string, action: IdentityAction) {
    const result = storeState.log?.filter(
      (log) => log.action === action && log.userId === userId
    )

    if (result?.length) {
      return result[result.length - 1]
    }
  }

  function userIsActive(userId: string) {
    const user = storeState.identities?.find((user) => user.id === userId)
    if (user?.activation_status === ACTIVATED) {
      return true
    }

    const result = getLastActionFor(userId, IdentityAction.Activate)

    if (result?.status === Status.Success) {
      return true
    }

    return false
  }

  return (
    <article>
      <Input
        onChange={handleChange}
        name="email"
        type="text"
        placeholder="Enter the email you want to search here"
        value={state.email}
      />
      {state.error ? (
        <Text mt={2} color={'red.600'}>
          {state.error}
        </Text>
      ) : null}
      <div className={styles.userDataWrapper}>
        {emailIsSame && storeState.status === Status.Loading ? (
          <p>LOADING</p>
        ) : null}

        {emailIsSame &&
        [Status.Error, Status.Success].includes(storeState.status) &&
        !storeState.identities.length ? (
          state.email ? (
            <p>We could not find any accounts for: {state.email}</p>
          ) : (
            <p>Please provide a valid email</p>
          )
        ) : null}

        {emailIsSame &&
        storeState.status === Status.Success &&
        storeState.identities.length ? (
          <div>
            <p className={styles.userDataCaption}>
              Displaying Accounts for: {state.email}{' '}
            </p>

            <ol className={styles.userDataOl}>
              {storeState.identities?.map((user) => (
                <li
                  className={classnames(
                    styles.userDataLi,
                    styles.userDataAccounts
                  )}
                  key={user.id}
                >
                  Account
                  <div className={styles.userData}>
                    <p className={styles.userKey}>User ID:</p>
                    <p className={styles.userValue}>{user.id}</p>
                    <Button onClick={() => resendVerification(user.id)}>
                      Resend Verification
                    </Button>
                  </div>
                  <div className={styles.userData}>
                    <p className={styles.userKey}>Authentication Method:</p>
                    <p className={styles.userValue}>{authMethod(user.id)}</p>
                  </div>
                  <div className={styles.userData}>
                    <p className={styles.userKey}>Activation Status:</p>
                    <p className={styles.userValue}>
                      {userIsActive(user.id) ? ACTIVATED : 'NOT ACTIVE'}
                    </p>
                    {!userIsActive(user.id) && (
                      <Button onClick={() => activateUser(user.id)}>
                        Activate Account
                      </Button>
                    )}
                  </div>
                  {user.activation_status !== ACTIVATED && (
                    <div className={styles.userData}>
                      <p className={styles.userKey}>Activate Account status:</p>
                      <p className={styles.userValue}>
                        {getLastActionFor(user.id, IdentityAction.Activate)
                          ?.status ?? 'xxxxx'}
                      </p>
                    </div>
                  )}
                  <div className={styles.userData}>
                    <p className={styles.userKey}>
                      Resend verification status:
                    </p>
                    <p className={styles.userValue}>
                      {getLastActionFor(
                        user.id,
                        IdentityAction.ResendVerification
                      )?.status ?? 'xxxxx'}
                    </p>
                  </div>
                  <ol className={styles.userDataOl}>
                    {user.orgs?.map((org) => (
                      <li key={org.org_id} className={styles.userDataLi}>
                        {Object.entries(org)?.map(([key, value]) => (
                          <div className={styles.userData} key={key}>
                            <p className={styles.userKey}>
                              {keyToDisplay[key as OrgProperty]}:
                            </p>
                            <p className={styles.userValue}>{value}</p>
                          </div>
                        ))}
                      </li>
                    ))}
                  </ol>
                </li>
              ))}
            </ol>
          </div>
        ) : null}
      </div>
    </article>
  )
}
