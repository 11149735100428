import { DataTag } from '@/components/DataTag/DataTag'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  getArgoLinkForCluster,
  getGrafanaLinkForCluster,
} from '@/features/LegacyHives/components/ExternalLinks'
import {
  useFindHivesByOrgId,
  useGetOrgUsers,
  useInternalApiKey,
} from '@/generated/http-clients/v2ApiComponents'
import { toTokenData } from '@/helpers/toTokenData'
import { GridItem } from '@chakra-ui/icons'
import {
  Box,
  Grid,
  HStack,
  ListItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { useMemo } from 'react'
import { legacyHivesRootRoute } from '@/router/rootRoutes'
import React from 'react'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { legacyHivesClusterDetailsRoute } from './LegacyHivesClusterDetails'

export const legacyHivesOrganizationDetailsRoute = createRoute({
  getParentRoute: () => legacyHivesRootRoute,
  path: '/orgs/$orgId',
  component: LegacyHivesOrganizationDetailsPage,
})

function LegacyHivesOrganizationDetailsPage() {
  const params = legacyHivesOrganizationDetailsRoute.useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const orgId = useMemo(() => {
    return params.orgId
  }, [params.orgId])

  const hives = useFindHivesByOrgId({
    pathParams: {
      orgId: orgId!,
    },
  })

  // TODO Oli: if/when console-backoffice-openapi-v2.yml gets updated and
  // users_info would be a required array with at least 1 element in
  // OrganizationHivesResponse, then we might be able to simplify this code
  function firstUserId() {
    const users = hives.data?.users_info || []
    if (users.length === 0) {
      return 'unknown_customer_id'
    }

    return btoa(users[0].user_id)
  }

  const internalApiKey = useInternalApiKey({
    pathParams: {
      orgId: orgId!,
    },
    queryParams: {
      // FIXME @RobinAtherton - Check out if this is correct but this is how the old implementation grabbed the customerId
      customerId: firstUserId(),
    },
  })

  const users = useGetOrgUsers({
    pathParams: {
      orgId: orgId!,
    },
  })

  const decodedToken = useMemo(() => {
    if (internalApiKey.data?.token!) {
      return toTokenData(internalApiKey.data?.token!)
    }
    return null
  }, [internalApiKey.data?.token])

  const breadCrumbs = [
    { name: 'Legacy Hives', path: legacyHivesRootRoute.to },
    { name: orgId, path: '' },
  ]

  function routeToClusterDetails(orgId: string, clusterId: string | undefined) {
    if (clusterId) {
      navigate({
        to: legacyHivesClusterDetailsRoute.to,
        params: {
          orgId,
          clusterId,
        },
      })
    } else {
      toast({
        title: 'Cluster currently not accessible.',
        status: 'warning',
      })
    }
  }

  return (
    <PageLayout
      title={'Organisation Overview'}
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      <Grid templateColumns="repeat(2, 1fr)" width={'100%'} gap={4}>
        <GridItem>
          <SectionCard title={'Details'}>
            <VStack alignItems={'start'}>
              <HStack justifyItems={'center'}>
                <Text fontSize={'1.1rem'}>Organisation ID: </Text>
                <DataTag
                  value={hives.data?.org_id}
                  fontWeight={500}
                  fontSize={'1rem'}
                >
                  {hives.data?.org_id}
                </DataTag>
              </HStack>
              <HStack justifyItems={'center'}>
                <Text fontSize={'1.1rem'}>Organisation Name: </Text>
                <DataTag
                  value={hives.data?.company_name}
                  fontWeight={500}
                  fontSize={'1rem'}
                >
                  {hives.data?.company_name}
                </DataTag>
              </HStack>
              <VStack alignItems={'start'}>
                <Text fontSize={'1.1rem'} fontWeight={500}>
                  Associated Emails
                </Text>
                <Box ml={4}>
                  <UnorderedList>
                    {hives.data?.email?.map((email) => (
                      <ListItem key={email.email_address} pb={1}>
                        <DataTag
                          value={email.email_address}
                          key={email.email_address}
                          fontWeight={500}
                          fontSize={'1rem'}
                        >
                          {email.email_address}
                        </DataTag>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </VStack>
              <VStack alignItems={'start'}>
                <Text fontSize={'1.1rem'} fontWeight={500}>
                  Associated Account IDs
                </Text>
                {hives.data?.users_info?.map((userInfo) => (
                  <Box key={userInfo.user_id} pl={4}>
                    <HStack justifyItems={'center'}>
                      <Text fontSize={'1.1rem'}>User ID:</Text>
                      <DataTag
                        value={userInfo.user_id}
                        key={userInfo.user_id}
                        fontWeight={500}
                        fontSize={'1rem'}
                      >
                        {userInfo.user_id}
                      </DataTag>
                    </HStack>
                    <HStack justifyItems={'center'}>
                      <Text fontSize={'1.1rem'}>Stripe ID:</Text>
                      <DataTag
                        value={userInfo.stripe_id}
                        key={userInfo.stripe_id}
                        fontWeight={500}
                        fontSize={'1rem'}
                      >
                        {userInfo.stripe_id}
                      </DataTag>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </VStack>
          </SectionCard>
        </GridItem>
        <GridItem>
          <SectionCard
            title={'Internal API-Token'}
            width={'100%'}
            height={'100%'}
          >
            <VStack alignItems={'start'}>
              <HStack>
                <Text fontSize={'1.1rem'} whiteSpace={'nowrap'}>
                  Token:{' '}
                </Text>
                <DataTag
                  value={decodedToken?.Token}
                  maxWidthPx={200}
                  fontWeight={500}
                  fontSize={'1rem'}
                >
                  {decodedToken?.Token}
                </DataTag>
              </HStack>
              <HStack>
                <Text fontSize={'1.1rem'}>Expiry Date: </Text>
                <DataTag
                  value={decodedToken?.Expiry}
                  fontWeight={500}
                  fontSize={'1rem'}
                >
                  {decodedToken?.Expiry}
                </DataTag>
              </HStack>
            </VStack>
          </SectionCard>
        </GridItem>
      </Grid>
      <SectionCard title={'Members'}>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Status</Th>
              <Th>Last Login</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.data?.map((user) => (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.username}</Td>
                <Td>{user.email}</Td>
                <Td>{user.role}</Td>
                <Td>{user.status}</Td>
                <Td>{user.lastLogin}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </SectionCard>

      {hives.data?.hives_info?.map((hive) => (
        <SectionCard
          key={hive.cluster_id}
          title="Hive Data"
          actions={
            <>
              <DataTag
                hasCopy={false}
                externalLink={getArgoLinkForCluster(
                  hive.apiary,
                  hive.cluster_id
                )}
              >
                Go to ArgoCD
              </DataTag>
              <DataTag
                hasCopy={false}
                externalLink={getGrafanaLinkForCluster(
                  hive.apiary,
                  hive.cluster_id
                )}
              >
                Go to Grafana
              </DataTag>
            </>
          }
        >
          <VStack alignItems={'start'}>
            <HStack>
              <Text fontSize={'1.1rem'}>Apiary: </Text>
              <DataTag value={hive.apiary} fontWeight={500} fontSize={'1rem'}>
                {hive.apiary}
              </DataTag>
            </HStack>
            <HStack>
              <Text fontSize={'1.1rem'}>Cluster ID: </Text>
              <DataTag
                value={hive.cluster_id}
                fontWeight={500}
                fontSize={'1rem'}
                onClickBody={() =>
                  routeToClusterDetails(orgId, hive.cluster_id)
                }
              >
                {hive.cluster_id}
              </DataTag>
            </HStack>
            <HStack>
              <Text fontSize={'1.1rem'}>Cluster URL: </Text>
              <DataTag
                value={hive.cluster_url}
                fontWeight={500}
                fontSize={'1rem'}
              >
                {hive.cluster_url}
              </DataTag>
            </HStack>
            <HStack>
              <Text fontSize={'1.1rem'}>Cluster Type: </Text>
              <DataTag value={hive.plan} fontWeight={500} fontSize={'1rem'}>
                {hive.plan}
              </DataTag>
            </HStack>
            <HStack>
              <Text fontSize={'1.1rem'}>Status: </Text>
              <DataTag
                value={hive.hive_status}
                fontWeight={500}
                fontSize={'1rem'}
              >
                {hive.hive_status}
              </DataTag>
            </HStack>
            <HStack>
              <Text fontSize={'1.1rem'}>Authentication Method: </Text>
              <DataTag
                value={hive.authentication_method}
                fontWeight={500}
                fontSize={'1rem'}
              >
                {hive.authentication_method}
              </DataTag>
            </HStack>
            {!!hive.broker_features.length && (
              <HStack>
                <Text fontSize={'1.1rem'}>Features: </Text>
                {hive.broker_features.map((feature) => (
                  <DataTag
                    colorScheme={'orange'}
                    value={feature}
                    key={feature}
                    hasCopy={false}
                  >
                    {feature}
                  </DataTag>
                ))}
              </HStack>
            )}
          </VStack>
        </SectionCard>
      ))}
    </PageLayout>
  )
}
