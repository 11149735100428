import { Box, Grid, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { PropsWithChildren, ReactNode } from 'react'
import React from 'react'

type PageLayoutProps = {
  /**
   * Show Element before the title, useful for buttons with back navigation
   */
  beforeTitle?: ReactNode
  title: ReactNode
  description?: ReactNode
  action?: ReactNode
  contentGap?: number
}

export function PageLayout({
  title,
  description,
  beforeTitle,
  action,
  children,
  contentGap = 6,
}: PropsWithChildren<PageLayoutProps>) {
  return (
    <Grid gridTemplateAreas={`"heading" "content"`} gap={4} mb={32}>
      <Grid gridArea="heading" w="100%" alignItems="center" gap={2}>
        {beforeTitle ? <Box w="100%">{beforeTitle}</Box> : null}

        <Grid gridTemplateAreas={`"heading" "content"`} gap={8} mb={32}>
          <VStack w="100%" alignItems="start" gap={4}>
            <HStack
              gridArea="heading"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Heading as="h1" variant="h1" m={0}>
                {title}
              </Heading>

              <Box>{action}</Box>
            </HStack>
            {description ? description : null}
          </VStack>

          <VStack
            gridArea="content"
            w="100%"
            alignItems="start"
            gap={contentGap}
          >
            {children}
          </VStack>
        </Grid>
      </Grid>
    </Grid>
  )
}
