import * as React from 'react'
import styles from './Sidebar.module.css'
import classnames from 'classnames'
import { Link, useMatches } from '@tanstack/react-router'
import { identitySupportRoute } from '@/features/IdentitySupport/pages/IdentitySupport'
import { clusterConfigTableRoute } from '@/features/ClusterConfigs/pages/ClusterConfigTable/ClusterConfigTable'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import {
  clustersRootRoute,
  legacyHivesRootRoute,
  templatesRootRoute,
} from '@/router/rootRoutes'
import { Icon } from '@chakra-ui/icons'
import { ExternalLink } from 'lucide-react'
import { legacyHivesRoute } from '@/features/LegacyHives/pages/LegacyHivesTable'
import { HStack, Tag, Text } from '@chakra-ui/react'

export function Sidebar() {
  const matches = useMatches()

  const options = [
    {
      title: 'Identity Support',
      route: identitySupportRoute,
      rootRoute: identitySupportRoute,
    },
    {
      title: 'Legacy Hives',
      tag: 'Old infra',
      route: legacyHivesRoute,
      rootRoute: legacyHivesRootRoute,
    },
    {
      title: 'Hives & Configs',
      tag: 'New',
      colorScheme: 'green',
      route: clusterConfigTableRoute,
      rootRoute: clustersRootRoute,
    },
    {
      title: 'Templates',
      route: templateTableRoute,
      rootRoute: templatesRootRoute,
    },
  ]

  return (
    <nav className={styles.sidebar}>
      <ol className={styles.sidebarOl}>
        {options.map((option) => (
          <li key={option.title} className={styles.sidebarLi}>
            <Link
              to={option.route.to}
              className={classnames([
                styles.sidebarLink,
                matches.some((match) => match.routeId === option.rootRoute.id)
                  ? styles.sidebarLinkActive
                  : undefined,
              ])}
            >
              <HStack
                width={'100%'}
                justifyContent={'space-between'}
                whiteSpace={'nowrap'}
                gap={3}
              >
                <Text>{option.title}</Text>
                {option.tag ? (
                  <Tag size={'sm'} colorScheme={option.colorScheme}>
                    {option.tag}
                  </Tag>
                ) : null}
              </HStack>
            </Link>
          </li>
        ))}
        <li key="doc" className={styles.sidebarLi}>
          <a
            href="https://docs.google.com/document/d/1z-snSivljxA-yn9F5eF5s7Ypi2MAmN7HR5IQU1oQh7Q/edit?tab=t.0"
            className={classnames(styles.sidebarLink)}
            target="doc"
          >
            Documentation
            <Icon ml="2" mb="2px" as={ExternalLink} w={4} h={4} />
          </a>
        </li>
      </ol>
    </nav>
  )
}
