import { useFindOrgById } from '@/generated/http-clients/v2ApiComponents'
import {
  useDeleteCluster,
  useGetCluster,
} from '@/generated/http-clients/v3ApiComponents'
import { clustersRootRoute } from '@/router/rootRoutes'
import {
  Button,
  Card,
  CardHeader,
  HStack,
  Icon,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  createRoute,
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from '@tanstack/react-router'
import * as React from 'react'
import { DataTag } from '@/components/DataTag/DataTag'
import { ExternalLink } from '@/components/ExternalLink/ExternalLink'
import { timeAgo } from '@/helpers/timeFormatter'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'
import { Modal, ModalBody } from '@chakra-ui/icons'
import { ContextMenu } from '@/features/ClusterConfigs/components/ContextMenu/ContextMenu'
import { clusterConfigTableRoute } from '../ClusterConfigTable/ClusterConfigTable'
import { Unplug } from 'lucide-react'

export const clusterConfigsRootRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: '$orgId/$clusterId/revision',
  component: () => <ClusterConfigsPage />,
})

const linkStyle = {
  whiteSpace: 'nowrap',
  color: 'var(--chakra-colors-blue-500)',
  fontWeight: 500,
  textDecoration: 'underline',
  alignItems: 'center',
}

const notApplicable = 'N/A'

function ClusterConfigsPage() {
  const deleteRequest = useDeleteCluster()
  const navigate = useNavigate()
  const toast = useToast()
  const pathParams = clusterConfigsRootRoute.useParams()

  const cluster = useGetCluster({ pathParams })
  const organization = useFindOrgById({
    pathParams: {
      orgId: pathParams.orgId,
    },
  })

  function deleteCluster() {
    if (!cluster.data?.system?.global?.hive_id) {
      return
    }
    deleteRequest
      .mutateAsync({
        pathParams: {
          orgId: pathParams.orgId,
          clusterId: cluster.data?.system?.global?.hive_id,
        },
      })
      .then(() => {
        toast({
          title: 'Request to delete hive was sent successfully',
          status: 'success',
          duration: 2000,
        })
        return navigate({
          to: clusterConfigTableRoute.to,
        })
      })
      .catch((error) => {
        console.error('error posting', error)
        toast({
          title: 'Failed to delete the hive',
          status: 'error',
          duration: 5000,
        })
      })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const withSidebar = useLocation().pathname.includes('compare') == false

  return (
    <HStack w="100%" alignItems="start" gap={6}>
      <VStack w={withSidebar ? '66%' : '100%'} alignItems="stretch">
        <Outlet />
      </VStack>

      {/* General Cluster overview mimic GitHub sidebar here */}
      {withSidebar ? (
        <VStack w="33%" position="sticky" top="70px">
          <Card>
            <HStack
              justifyContent={'space-between'}
              px={6}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <CardHeader
                fontWeight="700"
                m={0}
                py={2}
                px={0}
                color="green.800"
              >
                Overview
              </CardHeader>
              <HStack gap={2}>
                {cluster.data?.ignore_for_reconcile ? (
                  <Tag size={'md'} colorScheme={'red'} gap={2}>
                    <Icon as={Unplug} />
                    Sync disabled
                  </Tag>
                ) : null}
                <ContextMenu
                  items={[{ itemName: 'Delete Hive', action: onOpen }]}
                />
              </HStack>
            </HStack>
            <Table>
              <Tbody>
                <Tr>
                  <Td>Hive ID</Td>
                  <Td>
                    <DataTag value={cluster.data?.system?.global?.hive_id}>
                      {cluster.data?.system?.global?.hive_id}
                    </DataTag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Hive Name</Td>
                  <Td>
                    <DataTag value={cluster.data?.system?.global?.name}>
                      {cluster.data?.system?.global?.name}
                    </DataTag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Organization ID</Td>
                  <Td>
                    <DataTag value={organization.data?.org_id}>
                      <Tooltip label="Go to Organization">
                        <Link
                          to={legacyHivesOrganizationDetailsRoute.to}
                          params={{
                            orgId: pathParams.orgId,
                          }}
                        >
                          <Text style={linkStyle}>
                            {organization.data?.org_id}
                          </Text>
                        </Link>
                      </Tooltip>
                    </DataTag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Organization Name</Td>
                  <Td>
                    <DataTag value={organization.data?.org_name}>
                      {organization.data?.org_name}
                    </DataTag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Template ID</Td>
                  <Td>
                    <Tooltip label="Go to template">
                      <Link
                        to="/templates/$templateId"
                        params={{
                          templateId: cluster.data?.template_id || '',
                        }}
                      >
                        <Text style={linkStyle}>
                          {cluster.data?.template_id}
                        </Text>
                      </Link>
                    </Tooltip>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Location</Td>
                  <Td>{cluster.data?.system?.global?.location}</Td>
                </Tr>
                <Tr>
                  <Td>Cloud Provider</Td>
                  <Td>{cluster.data?.system?.global?.cloud_provider}</Td>
                </Tr>
              </Tbody>
            </Table>
            <CardHeader
              fontWeight="700"
              my={0}
              mt={6}
              py={2}
              px={6}
              borderBottom="1px solid"
              borderColor="gray.200"
              color="green.800"
            >
              Applied Revision
            </CardHeader>
            <Table>
              <Tbody>
                <Tr>
                  <Td>Revision ID</Td>
                  <Td>
                    <DataTag value={cluster.data?.rollout?.revision_id}>
                      {cluster.data?.rollout?.revision_id}
                    </DataTag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Rollout State</Td>
                  <Td>
                    <Text>{cluster.data?.rollout?.state || 'N/A'}</Text>

                    {/* display error message if provided */}
                    {cluster.data?.rollout?.state_message ? (
                      <Text color={'text.text-subtle'} fontSize={'sm'}>
                        {cluster.data?.rollout?.state_message}
                      </Text>
                    ) : null}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Hive State</Td>
                  <Td>{cluster.data?.hive_state?.state || 'N/A'}</Td>
                </Tr>

                <Tr>
                  <Td>Created By</Td>
                  <Td>
                    <ExternalLink
                      href={`mailto:${
                        cluster.data?.rollout?.created_by || notApplicable
                      }`}
                    >
                      {cluster.data?.rollout?.created_by || notApplicable}
                    </ExternalLink>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Approved By</Td>
                  <Td>
                    <ExternalLink
                      href={`mailto:${
                        cluster.data?.rollout?.approved_by || notApplicable
                      }`}
                    >
                      {cluster.data?.rollout?.approved_by || notApplicable}
                    </ExternalLink>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Created At</Td>
                  <Td>
                    <DateCombination date={cluster.data?.rollout?.created_at} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Updated At</Td>
                  <Td>
                    <DateCombination date={cluster.data?.rollout?.updated_at} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Version</Td>
                  <Td>{cluster.data?.rollout?.version || notApplicable}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Card>
        </VStack>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete this Hive?</ModalHeader>
          <ModalBody>
            <Text>This will delete this hive and all of its revisions.</Text>
            <HStack mt={4} pb={3} justifyContent={'end'}>
              <Button variant={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button variant={'dangerous'} onClick={deleteCluster}>
                Delete Hive
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  )
}

/**
 * Get all information about a date at a glance
 * I do not want to compute all this date stuff
 * Only the Time God is smart enough to do that ⏲️
 */
function DateCombination({ date }: { date?: string }) {
  if (!date) return

  return (
    <VStack gap={2} alignItems="start">
      <Text>{timeAgo(date)}</Text>
      <Text fontSize={'sm'} color="text.text-subtle">
        {date}
      </Text>
    </VStack>
  )
}
