import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import * as React from 'react'
import { useRef, useState } from 'react'
import { clustersRootRoute } from '@/router/rootRoutes'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { EditorWrapper } from '@/features/Templates/components/EditorWrapper'
import { CheckIcon, ChevronDown } from 'lucide-react'
import {
  useGetTemplates,
  usePostCluster,
} from '@/generated/http-clients/v3ApiComponents'
import YAML from 'yaml'
import SearchMenu from '@/components/SearchMenu/SearchMenu'
import { TemplateDetail } from '@/generated/http-clients/v3ApiSchemas'
import { useGetOrgs } from '@/generated/http-clients/v2ApiComponents'

type State = {
  page: number
  data: Array<TemplateDetail>
  hasNext: boolean
}

export const newClusterConfigRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: 'new',
  component: () => <NewClusterConfig />,
})

export function NewClusterConfig() {
  const popoverTextareaRef = useRef(null)
  const navigate = useNavigate()
  const toast = useToast()

  const [organizationId, setOrganizationId] = useState('')

  function handleChangeLogInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target
    setRequest((previous) => ({
      ...previous,
      changelog: value,
    }))
  }

  function onBaseTemplateConfigChange(yaml: string) {
    if (!yaml) {
      return
    }

    setRequest((previousState) => {
      return {
        ...previousState,
        template_id: yaml,
      }
    })
  }

  function onSystemConfigChange(yaml: string) {
    if (!yaml) {
      return
    }

    setRequest((previousState) => {
      return {
        ...previousState,
        system: yaml,
      }
    })
  }

  function onOverridesConfigYamlChange(yaml: string) {
    if (!yaml) {
      return
    }

    setRequest((previousState) => {
      return {
        ...previousState,
        override: yaml,
      }
    })
  }

  function onCustomerChange(yaml: string) {
    if (!yaml) {
      return
    }

    setRequest((previousState) => {
      return {
        ...previousState,
        customer: yaml,
      }
    })
  }

  const createCluster = usePostCluster({})

  /**
   * FIXME @RobinAtherton - This is a budget validation block before we have proper validation for the editors.
   * FIXME Currently this also only shows the most top error if there are multiple.
   */
  function noConfigsAreEmpty() {
    if (request.template_id.length === 0) {
      toast({
        title: 'Base template config missing',
        description: 'Make sure you fill the base template editor.',
        status: 'error',
      })
      return false
    }
    if (request.system.length === 0) {
      toast({
        title: 'System config missing',
        description: 'Make sure you fill the base template editor.',
        status: 'error',
      })
      return false
    }
    if (request.customer.length === 0) {
      toast({
        title: 'Customer config missing',
        description: 'Make sure you fill the base template editor.',
        status: 'error',
      })
      return false
    }
    return true
  }

  function hasOrgId() {
    return organizationId.length
  }

  function hasFilledMandatoryFields() {
    return (
      hasOrgId() &&
      request.template_id.length &&
      request.system.length &&
      request.customer.length
    )
  }

  const [request, setRequest] = useState({
    system: '',
    override: '',
    customer: '',
    template_id: '',
  })

  function submit() {
    if (!noConfigsAreEmpty() || !hasOrgId()) {
      return
    }

    const { system, override, customer, template_id } = request

    try {
      const yamlConfig = `${system}\n${override}\n${customer}\ntemplate_id: ${template_id}`
      const jsonConfig = {
        ...YAML.parse(yamlConfig),
      }

      createCluster
        .mutateAsync({
          pathParams: {
            orgId: organizationId,
          },
          body: jsonConfig,
        })
        .then(() => {
          toast({
            title: 'Created cluster config successfully',
            status: 'success',
            duration: 2000,
          })
          return navigate({
            to: clustersRootRoute.to,
          })
        })
        .catch((error) => {
          console.log('error posting', error)
          toast({
            title: 'Failed to submit the new config',
            status: 'error',
            duration: 5000,
          })
        })
    } catch (error) {
      console.error('Failed to parse YAML', error)
      // TODO: look at integrating a YAML validator to display errors. A schema would need to be provided by the backend via an API
      toast({
        title: 'This configuration cannot be submitted as the YAML is invalid.',
        status: 'error',
        duration: 5000,
      })
    }
  }

  const [templatesState, setTemplatesState] = useState<State>({
    page: 1,
    data: [],
    hasNext: false,
  })

  const { data: templatesData, isLoading: templatesIsLoading } =
    useGetTemplates({
      queryParams: { page: templatesState.page, order: 'desc' },
    })

  const { data: orgsData, isLoading: orgsIsLoading } = useGetOrgs({})

  React.useEffect(() => {
    if (templatesData?.templates) {
      setTemplatesState((state) => ({
        ...state,
        hasNext: templatesData.has_next_page ?? false,
        data: [...state.data, ...templatesData.templates],
      }))
    }
  }, [templatesData])

  return (
    <PageLayout
      title={'Create a cluster config'}
      action={
        <Popover placement="bottom-end" initialFocusRef={popoverTextareaRef}>
          <PopoverTrigger>
            <Button rightIcon={<Icon as={ChevronDown} mr={-1} />}>
              Review config
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold" border={0}>
              Submit your config
              <PopoverCloseButton top={2} />
            </PopoverHeader>
            {/*  FIXME need to trigger submit function somewhere here*/}
            <PopoverBody>
              <Textarea
                placeholder="Leave a comment"
                name={'changeLog'}
                onChange={handleChangeLogInput}
                // onKeyDown={handleKeyDown} // Add keydown listener
                ref={popoverTextareaRef}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent={'end'} border={0}>
              <Button
                variant="primary"
                leftIcon={<Icon as={CheckIcon} ml={-1} />}
                onClick={submit}
                isDisabled={!hasFilledMandatoryFields()}
              >
                Submit
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      }
    >
      <FormControl isRequired>
        <FormLabel>Organization ID</FormLabel>
        <SearchMenu
          data={(orgsData || []).map((data) => ({
            value: data.org_id,
            tag: data.org_name,
          }))}
          title="Please select an Organization ID"
          loading={orgsIsLoading}
          morePages={false}
          onChange={(value) => setOrganizationId(value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Base Template</FormLabel>
        <SearchMenu
          data={(templatesState.data || []).map((data) => ({
            value: data.template_id,
            tag: data.name,
          }))}
          title="Please select a Base Template"
          loading={templatesIsLoading}
          morePages={templatesState.hasNext}
          onLoadMore={() =>
            setTemplatesState((state) => ({ ...state, page: state.page + 1 }))
          }
          onChange={(value) => onBaseTemplateConfigChange(value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel fontSize={24}>System</FormLabel>
        <Box mt={6}>
          <EditorWrapper onChange={onSystemConfigChange} />
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel fontSize={24}>Overrides</FormLabel>
        <Box mt={6}>
          <EditorWrapper onChange={onOverridesConfigYamlChange} />
        </Box>
      </FormControl>
      <FormControl isRequired>
        <FormLabel fontSize={24}>Customer</FormLabel>
        <Box mt={6}>
          <EditorWrapper onChange={onCustomerChange} />
        </Box>
      </FormControl>
    </PageLayout>
  )
}
