import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Button, HStack, Image } from '@chakra-ui/react'

export function NavigationBar() {
  const { oktaAuth } = useOktaAuth()

  return (
    <HStack
      as="nav"
      w={'100%'}
      borderBottom={'1px solid'}
      px={4}
      justify={'space-between'}
    >
      <Image src="/logo-bee-bw.svg" boxSize={10} alt="HiveMQ backoffice logo" />
      <Button
        type="button"
        variant={'simple'}
        onClick={() =>
          oktaAuth.signOut({
            clearTokensBeforeRedirect: true,
            postLogoutRedirectUri: window.location.origin,
          })
        }
      >
        LogOut
      </Button>
    </HStack>
  )
}
