import { DataTag } from '@/components/DataTag/DataTag'
import { useGetTemplates } from '@/generated/http-clients/v3ApiComponents'
import { timeAgo } from '@/helpers/timeFormatter'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { createRoute, Link, useNavigate } from '@tanstack/react-router'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { templatesRootRoute } from '../../../router/rootRoutes'
import * as React from 'react'

export const templateTableRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: '/',
  component: () => <TemplateTable />,
})

export function TemplateTable() {
  const nav = useNavigate()
  const [page, setPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const templates = useGetTemplates(
    {
      queryParams: { page, order: 'desc' },
    },
    {
      refetchInterval: 20000,
    }
  )

  // detect when the list of templates has been updated
  React.useEffect(() => {
    // when the templates data is update, check whether items
    // in selectedRows still exist in the template list.
    // Remove all the ones that do not exist anymore.
    const validIds =
      templates?.data?.templates?.map((item) => item.template_id) || []
    setSelectedRows((prevSelected) =>
      prevSelected.filter((id) => validIds.includes(id))
    )
  }, [templates?.data?.templates])

  function handleNextPage() {
    if (templates.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  function handleCheckboxChange(id: string) {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
    } else if (selectedRows.length < 2) {
      setSelectedRows([...selectedRows, id])
    }
  }

  const handleCompare = () => {
    // Add your comparison logic here
    nav({
      to: `/templates/compare/$templateId0/$templateId1`,
      params: { templateId0: selectedRows[0], templateId1: selectedRows[1] },
    })
  }

  return (
    <Flex direction="column" gap="8">
      <Flex justifyContent={'space-between'}>
        <Heading as="h1" variant="h1">
          Templates
        </Heading>
        <Flex justifyContent={'flex-end'} mb={'10'} gap={'4'}>
          {!templates.isLoading &&
          (templates.data?.templates || []).length > 1 ? (
            <Tooltip
              label={
                selectedRows.length !== 2 ? 'Select 2 revisions to compare' : ''
              }
            >
              <Button
                variant={'outline'}
                onClick={handleCompare}
                isDisabled={selectedRows.length !== 2}
              >
                Compare changes
              </Button>
            </Tooltip>
          ) : null}
          <Link to="/templates/create">
            <Button variant="primary" leftIcon={<Icon as={Plus} ml={-1} />}>
              New template
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th width="32px" paddingRight={0}></Th>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Tags</Th>
          </Tr>
        </Thead>
        <Tbody>
          {templates.isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <Tr key={index}>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Td key={index}>
                      <Skeleton height="20px" />
                    </Td>
                  ))}
                </Tr>
              ))
            : null}

          {templates?.data?.templates?.map((template) => (
            <Tr key={template.template_id}>
              <Td pr={0}>
                <Checkbox
                  isChecked={selectedRows.includes(template.template_id)}
                  onChange={() => handleCheckboxChange(template.template_id)}
                  isDisabled={
                    selectedRows.length === 2 &&
                    !selectedRows.includes(template.template_id)
                  }
                />
              </Td>
              <Td>
                {/* Template ID */}
                <DataTag value={template.template_id} hasCopy={false}>
                  {template.template_id}
                </DataTag>
              </Td>
              <Td>
                {/* Template Name, creation date and author */}
                <Link
                  to="/templates/$templateId"
                  params={{ templateId: template.template_id }}
                >
                  <Text
                    variant="P2"
                    fontWeight={700}
                    whiteSpace={'nowrap'}
                    maxWidth={230}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    _hover={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {template.name}
                  </Text>
                </Link>
                <Text fontSize="sm" color="gray">
                  Created {timeAgo(template.created_at!)} by{' '}
                  {template.created_by}
                </Text>
              </Td>
              <Td>
                {/* Tags */}
                <HStack gap={1}>
                  {template.tags?.map((tag) => (
                    <DataTag
                      key={tag}
                      value={tag}
                      colorScheme={'orange'}
                      hasCopy={false}
                    >
                      {tag}
                    </DataTag>
                  ))}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="space-between" mt={4} width="100%">
        <Button onClick={handlePreviousPage} isDisabled={page === 1}>
          Previous
        </Button>
        <Box alignContent={'center'}>
          Page {templates.data?.current_page} of {templates.data?.total_pages}
        </Box>
        <Button
          onClick={handleNextPage}
          isDisabled={!templates.data?.has_next_page}
        >
          Next
        </Button>
      </Box>
    </Flex>
  )
}
