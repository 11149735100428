import { DataTag } from '@/components/DataTag/DataTag'
import { useGetAllClustersRevisions } from '@/generated/http-clients/v3ApiComponents'
import { timeAgo } from '@/helpers/timeFormatter'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { createRoute, Link, useNavigate } from '@tanstack/react-router'
import { Plus, Unplug } from 'lucide-react'
import { useState } from 'react'
import { clustersRootRoute } from '@/router/rootRoutes'
import * as React from 'react'
import { LatestClusterRevision } from '@/generated/http-clients/v3ApiSchemas'
import capitalize from 'lodash/capitalize'

import styles from './clusterConfigsTable.module.css'
import revisionStates from '../../utils/revisionStates'
import { clusterConfigsRootRoute } from '../ClusterConfigsPage/ClusterConfigsPage'
import { ClusterConfigCombinedStates } from '@/features/ClusterConfigs/components/States/ClusterConfigCombinedStates'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'

export const clusterConfigTableRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: '/',
  component: () => <ClusterConfigTable />,
})

export function ClusterConfigTable() {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const clusters = useGetAllClustersRevisions(
    {
      queryParams: { page, order: 'desc' },
    },
    {
      refetchInterval: 20000, // 20s
    }
  )

  function handleNextPage() {
    if (clusters.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  function hiveNameClassName(revision: LatestClusterRevision) {
    if (revisionStates.isDisabled(revision)) {
      return styles.hiveNameDisabled
    }
    return ''
  }

  function revisionActionMetadata(latestRevision: LatestClusterRevision) {
    const { revision } = latestRevision
    let time = revision.rollout?.updated_at
      ? timeAgo(revision.rollout?.updated_at || '')
      : undefined
    const by = `by ${
      revisionStates.isSubmitted(latestRevision)
        ? revision.rollout?.created_by
        : revision.rollout?.approved_by
    }`
    // only in the submitted case: if updated_at is undefined but created_at is, use that field
    if (
      revisionStates.isSubmitted(latestRevision) &&
      !time &&
      revision.rollout?.created_at
    ) {
      time = timeAgo(revision.rollout?.created_at)
    }

    return `${time ? `${time} ` : ''}${by}`
  }

  return (
    <PageLayout
      title="Hives & Configs"
      action={
        <Link to="/clusters/new">
          <Button variant="primary" leftIcon={<Icon as={Plus} ml={-1} />}>
            New config
          </Button>
        </Link>
      }
    >
      {/* TODO Oli: Filters will go here */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width="32px" paddingRight="0" />
            <Th pl={4}>Name</Th>
            <Th>Org ID</Th>
            <Th>Hive ID</Th>
            <Th pl={4}>Apiary</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clusters.isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <Tr key={index}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Td key={index}>
                      <Skeleton height="20px" />
                    </Td>
                  ))}
                </Tr>
              ))
            : null}

          {clusters?.data?.revisions?.map((latestRevision) => {
            const { revision } = latestRevision
            return (
              <Tr
                key={revision.hive_id}
                className={styles.trAlignContentTop}
                _hover={{ backgroundColor: 'gray.100' }}
              >
                <Td className={styles.trAlignContentTopTdIcon}>
                  <ClusterConfigCombinedStates revision={latestRevision} />
                </Td>
                <Td>
                  {/* // Hive Name */}
                  <Flex direction={'column'} gap="1">
                    <HStack justifyContent={'space-between'}>
                      <Link
                        to={clusterConfigsRootRoute.to}
                        params={{
                          orgId: revision.org_id,
                          clusterId: revision.hive_id,
                        }}
                        style={{ width: 'fit-content' }}
                      >
                        <Text
                          variant="P2"
                          fontWeight={700}
                          whiteSpace={'nowrap'}
                          maxWidth={230}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          className={hiveNameClassName(latestRevision)}
                          _hover={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {revision.hive_name}
                        </Text>
                      </Link>
                      {latestRevision.ignore_for_reconcile ? (
                        <Tag size={'md'} colorScheme={'red'} gap={2}>
                          <Icon as={Unplug} />
                          Sync disabled
                        </Tag>
                      ) : null}
                    </HStack>
                    <Text fontSize="sm" color="gray">
                      {capitalize(revision.approval_state.state)}{' '}
                      {revisionActionMetadata(latestRevision)}
                    </Text>
                  </Flex>
                </Td>
                <Td width={100}>
                  {/* // Org ID */}
                  <DataTag
                    fontWeight={400}
                    value={revision.org_id}
                    hasCopy={true}
                    onClickBody={() =>
                      navigate({
                        to: legacyHivesOrganizationDetailsRoute.to,
                        params: {
                          orgId: revision.org_id,
                        },
                      })
                    }
                  >
                    {revision.org_id}
                  </DataTag>
                </Td>
                <Td width={100}>
                  {/* // Hive ID */}
                  <DataTag
                    fontWeight={400}
                    value={revision.hive_id}
                    hasCopy={true}
                    maxWidthPx={200}
                  >
                    {revision.hive_id}
                  </DataTag>
                </Td>
                <Td width={100}>
                  {/* // Apiary */}
                  <Text fontSize={'sm'}>{revision.apiary_id}</Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="space-between" mt={4} width="100%">
        <Button onClick={handlePreviousPage} isDisabled={page === 1}>
          Previous
        </Button>
        <Box alignContent={'center'}>
          Page {clusters.data?.current_page} of {clusters.data?.total_pages}
        </Box>
        <Button
          onClick={handleNextPage}
          isDisabled={!clusters.data?.has_next_page}
        >
          Next
        </Button>
      </Box>
    </PageLayout>
  )
}
