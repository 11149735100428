import { VStack, Heading, Spinner } from '@chakra-ui/react'
import { ReactEditor } from '@/components/Editor/Editor'
import React from 'react'
import { camelCase } from 'lodash'

type EditableSectionProps = {
  title: string
  value: string
  onChange: (newValue: string) => void
  editorOptions?: object
  isReadOnly?: boolean
}

export function EditableSection({
  title,
  value,
  onChange,
  editorOptions = {},
  isReadOnly = false,
}: EditableSectionProps) {
  return (
    <VStack alignItems="stretch" gap={2}>
      <Heading as="h2" variant="h3">
        {title}
      </Heading>
      <ReactEditor
        language="yaml"
        loading={<Spinner my={8} />}
        value={value}
        options={{
          ...editorOptions,
          readOnly: isReadOnly,
        }}
        onChange={(newData) => {
          if (!newData) return
          onChange(newData)
        }}
      />
    </VStack>
  )
}
