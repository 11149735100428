import { clusterConfigTableRoute } from '@/features/ClusterConfigs/pages/ClusterConfigTable/ClusterConfigTable'
import { templateCreateRoute } from '@/features/Templates/components/TemplateCreate'
import { templateDetailsRoute } from '@/features/Templates/components/TemplateDetails'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import { clusterConfigDraftRoute } from '@/features/ClusterConfigs/pages/ClusterConfigDraft/ClusterConfigDraft'
import { clusterConfigRevisionsRoute } from '@/features/ClusterConfigs/pages/ClusterConfigRevisions/ClusterConfigRevisions'
import { identitySupportRoute } from '@/features/IdentitySupport/pages/IdentitySupport'
import { newClusterConfigRoute } from '@/features/ClusterConfigs/components/NewClusterConfig/NewClusterConfig'
import { createRouter } from '@tanstack/react-router'
import { clusterConfigsRootRoute } from '@/features/ClusterConfigs/pages/ClusterConfigsPage/ClusterConfigsPage'
import { loginCallbackRoute, loginRoute } from './authentication/login'
import {
  applicationRootRoute,
  clustersRootRoute,
  rootRoute,
  legacyHivesRootRoute,
  templatesRootRoute,
} from './rootRoutes'
import { dynamicTemplateComparison } from '@/features/Templates/components/TemplateComparison'
import { legacyHivesRoute } from '@/features/LegacyHives/pages/LegacyHivesTable'
import { configRevisionComparison } from '@/features/ClusterConfigs/components/States/ConfigRevisionComparison'
import { legacyHivesClusterDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesClusterDetails'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'
import { clusterConfigDiffRoute } from '@/features/ClusterConfigs/pages/ClusterConfigDiff/ClusterRevisionDetails'

const routeTree = rootRoute.addChildren([
  /**
   * Main application routes that define the inner contents of the application after a successful login
   */
  applicationRootRoute.addChildren([
    /**
     * All cluster routes nested withing `/clusters` as of now
     */
    clustersRootRoute.addChildren([
      clusterConfigTableRoute,
      newClusterConfigRoute,

      clusterConfigsRootRoute.addChildren([
        clusterConfigRevisionsRoute,
        clusterConfigDiffRoute,
        clusterConfigDraftRoute,
        configRevisionComparison,
      ]),
    ]),

    /**
     * All functionality related to templates nested withing `/templates` as of now
     */
    templatesRootRoute.addChildren([
      templateTableRoute,
      templateCreateRoute,
      templateDetailsRoute,
      dynamicTemplateComparison,
    ]),

    /**
     * All functionality related to identity support nested withing `/identity` as of now
     */
    identitySupportRoute,

    /**
     * Old infra route, these are all hives not located on the unified infra
     */

    legacyHivesRootRoute.addChildren([
      legacyHivesRoute,
      legacyHivesClusterDetailsRoute,
      legacyHivesOrganizationDetailsRoute,
    ]),
  ]),

  /**
   * !important: do not remove these routes
   * Authentication routes that are not part of the applicationRootRoute
   * and handle the whole authentication flow via okta
   */
  loginRoute,
  loginCallbackRoute,
])

export const router = createRouter({
  routeTree,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
