import * as React from 'react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { clusterConfigsRootRoute } from '../ClusterConfigsPage/ClusterConfigsPage'
import {
  Button,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Spinner,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, ChevronDown } from 'lucide-react'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  useGetCluster,
  usePutCluster,
} from '@/generated/http-clients/v3ApiComponents'
import { ReactEditor } from '@/components/Editor/Editor'
import YAML from 'yaml'
import { useRef, useState } from 'react'
import { clusterConfigTableRoute } from '../ClusterConfigTable/ClusterConfigTable'
import { useHandleKeyDown } from '../../utils/handleKeyDown'
import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { UseQueryResult } from '@tanstack/react-query'
import { router } from '@/router'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'

export const clusterConfigDraftRoute = createRoute({
  getParentRoute: () => clusterConfigsRootRoute,
  path: '$baseRevisionId/draft',
  component: () => <ClusterConfigDraft />,
})

const editorOptions = {
  language: 'yaml',
  automaticLayout: true,
  scrollBeyondLastLine: false,
  tabSize: 4,
  insertSpaces: true,
}

export function ClusterConfigDraft() {
  const pathParams = clusterConfigDraftRoute.useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const cluster = useGetCluster({ pathParams })

  const popoverTextareaRef = useRef(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [changedState, setChangedState] = useState({
    system: '',
    override: '',
    customer: '',
    template_id: '',
    comment: '',
    readyToSubmit: false,
  })

  React.useEffect(() => {
    if (!cluster.isSuccess) {
      return
    }

    setChangedState({
      system: YAML.stringify({
        system: cluster.data?.system,
      }),
      override: YAML.stringify({
        override: cluster.data?.override,
      }),
      customer: YAML.stringify({
        customer: cluster.data?.customer,
      }),
      template_id: YAML.stringify({
        template_id: cluster.data?.template_id,
      }),
      comment: '',
      readyToSubmit: false,
    })
  }, [cluster.isSuccess])

  const createClusterConfigRevision = usePutCluster()

  const updateComment = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const updatedComment = event.target.value
    setChangedState((previousState) => {
      return {
        ...previousState,
        comment: updatedComment,
        readyToSubmit: updatedComment.trim().length > 0,
      }
    })
  }

  function submitNewRevision() {
    if (!changedState.readyToSubmit) {
      return
    }

    try {
      const { system, override, customer, template_id, comment } = changedState
      const yamlConfig = `${system}${override}${customer}${template_id}`
      const jsonConfig = {
        ...YAML.parse(yamlConfig),
        changelog: comment,
      }

      createClusterConfigRevision
        .mutateAsync({
          body: jsonConfig,
          pathParams,
        })
        .then(() => {
          toast({
            title: 'Created revision successfully',
            status: 'success',
            duration: 2000,
          })
          return navigate({
            to: clusterConfigsRootRoute.to,
            params: pathParams,
          })
        })
        .catch((error) => {
          console.log('error posting', error)
          toast({
            title: 'Failed to submit the new revision',
            status: 'error',
            duration: 5000,
          })
        })
    } catch (error) {
      console.error('Failed to parse YAML', error)
      // TODO: look at integrating a YAML validator to display errors. A schema would need to be provided by the backend via an API
      toast({
        title: 'This configuration cannot be submitted as the YAML is invalid.',
        status: 'error',
        duration: 5000,
      })
    }
  }

  const breadCrumbs = [
    { name: 'Hives & Configs', path: clusterConfigTableRoute.to },
    {
      name: pathParams.clusterId,
      path: router.buildLocation({
        to: clusterConfigsRootRoute.to,
        params: pathParams,
      }).href,
    },
    { name: 'New Cluster Revision', path: '' },
  ]

  return (
    <PageLayout
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
      title="New cluster revision"
      action={
        <Popover placement="bottom-end" initialFocusRef={popoverTextareaRef}>
          <PopoverTrigger>
            {!cluster.isError && !cluster.isLoading ? (
              <Button
                variant="primary"
                rightIcon={<Icon as={ChevronDown} mr={-1} />}
              >
                Review changes
              </Button>
            ) : (
              <></>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold" border={0}>
              Submit your draft
              <PopoverCloseButton top={2} />
            </PopoverHeader>
            <PopoverBody>
              <Textarea
                placeholder="Leave a comment"
                value={changedState.comment}
                onChange={updateComment}
                onKeyDown={useHandleKeyDown(submitButtonRef)} // Add keydown listener
                ref={popoverTextareaRef}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent={'end'} border={0}>
              <Button
                variant="primary"
                leftIcon={<Icon as={CheckIcon} ml={-1} />}
                onClick={submitNewRevision}
                ref={submitButtonRef}
                isDisabled={!changedState.readyToSubmit}
              >
                Submit
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      }
    >
      <>
        {cluster.isLoading ? <Skeleton h="400px" /> : null}

        {cluster.isError ? (
          <ApiErrorState
            apiResource={cluster as unknown as UseQueryResult}
            errorTitle="An error happened"
            errorDescription="We failed to load the data from the server for the cluster revision"
          />
        ) : null}

        {cluster.isSuccess && cluster.data !== null ? (
          <VStack width="100%" alignItems="stretch" gap={6}>
            <VStack alignItems="stretch" gap={2}>
              <Heading as="h2" variant="h3">
                System
              </Heading>
              <ReactEditor
                language="yaml"
                loading={<Spinner my={8} />}
                value={changedState.system}
                options={{
                  ...editorOptions,
                  readOnly: false,
                }}
                onChange={(newData) => {
                  if (!newData) {
                    return
                  }

                  setChangedState((previousState) => {
                    return {
                      ...previousState,
                      system: newData,
                    }
                  })
                }}
              />
            </VStack>

            <VStack alignItems="stretch" gap={2}>
              <Heading as="h2" variant="h3">
                Overrides
              </Heading>
              <ReactEditor
                language="yaml"
                loading={<Spinner my={8} />}
                value={changedState.override}
                options={editorOptions}
                onChange={(newData) => {
                  if (!newData) {
                    return
                  }

                  setChangedState((previousState) => {
                    return {
                      ...previousState,
                      override: newData,
                    }
                  })
                }}
              />
            </VStack>

            <VStack alignItems="stretch" gap={2}>
              <Heading as="h2" variant="h3">
                Customer
              </Heading>
              <ReactEditor
                language="yaml"
                loading={<Spinner my={8} />}
                value={changedState.customer}
                options={editorOptions}
                onChange={(newData) => {
                  if (!newData) {
                    return
                  }

                  setChangedState((previousState) => {
                    return {
                      ...previousState,
                      customer: newData,
                    }
                  })
                }}
              />
            </VStack>

            {/* TODO make this a drop down */}
            <VStack alignItems="stretch" gap={2}>
              <Heading as="h2" variant="h3">
                Base Template
              </Heading>
              <ReactEditor
                language="yaml"
                loading={<Spinner my={8} />}
                value={changedState.template_id}
                options={editorOptions}
                onChange={(newData) => {
                  if (!newData) {
                    return
                  }

                  setChangedState((previousState) => {
                    return {
                      ...previousState,
                      template_id: newData,
                    }
                  })
                }}
              />
            </VStack>
          </VStack>
        ) : null}
      </>
    </PageLayout>
  )
}
