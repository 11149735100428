import { createRoute, useNavigate } from '@tanstack/react-router'
import { legacyHivesRootRoute } from '@/router/rootRoutes'
import { useFindHives } from '@/generated/http-clients/v2ApiComponents'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  Box,
  Button,
  HStack,
  Input,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { DataTag } from '@/components/DataTag/DataTag'
import { Check, CircleAlert, CircleMinus, RotateCcw } from 'lucide-react'
import styles from '@/features/ClusterConfigs/components/States/clusterConfigState.module.css'
import { legacyHivesClusterDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesClusterDetails'
import { flattenHivesResponse } from '@/features/LegacyHives/util'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'

export const legacyHivesRoute = createRoute({
  getParentRoute: () => legacyHivesRootRoute,
  path: '/',
  component: LegacyHivesTable,
})

function LegacyHivesTable() {
  const [page, setPage] = useState(1) // Current page
  const [filter, setFilter] = useState('') // Filter text
  const hivesRequest = useFindHives({ queryParams: { page: page } })
  const navigate = useNavigate()
  const toast = useToast()

  function handleNextPage() {
    if (hivesRequest.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  function routeToClusterDetails(orgId: string, clusterId: string | undefined) {
    if (clusterId) {
      navigate({
        to: legacyHivesClusterDetailsRoute.to,
        params: {
          orgId,
          clusterId,
        },
      })
    } else {
      toast({
        title: 'Cluster currently not accessible.',
        status: 'warning',
      })
    }
  }

  function routeToOrganizationDetails(orgId: string) {
    navigate({
      to: legacyHivesOrganizationDetailsRoute.to,
      params: {
        orgId,
      },
    })
  }

  /**
   * Memo to hold the flattened data to make it easier to iterate over in the table.
   */
  const legacyHivesTableData = useMemo(() => {
    return flattenHivesResponse(hivesRequest.data?.hives)
  }, [hivesRequest])

  const filteredHives = legacyHivesTableData?.filter(
    (hive) =>
      hive.hive_info?.cluster_url
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      hive.org_id?.toLowerCase().includes(filter.toLowerCase()) ||
      hive.company_name?.toLowerCase().includes(filter.toLowerCase()) ||
      hive.email?.email_address?.toLowerCase().includes(filter.toLowerCase())
  )

  /**
   *
   * "RUNNING" | "PENDING" | "ERROR" | "DELETING" | "LOCKED"
   * @param status the input status
   */
  function getColorForStatus(status: string | undefined) {
    if (status === undefined) {
      return 'orange.200'
    }
    if (status === 'RUNNING') {
      return 'green.200'
    }
    if (status === 'PENDING') {
      return 'orange.200'
    }
    if (status === 'ERROR') {
      return 'red.400'
    }
    if (status === 'DELETING') {
      return 'red.300'
    }
    if (status === 'LOCKED') {
      return 'blue.300'
    }
  }

  const breadCrumbs = [{ name: 'Legacy Hives', path: legacyHivesRootRoute.to }]

  return (
    <PageLayout
      title={'Legacy Hives'}
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      <Box mb={4} width={'50%'}>
        <Input
          placeholder="Filter by company name, organization id, cluster url, or email"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
        />
      </Box>
      {filteredHives?.length === 0 ? (
        <Text>No hives found for the organization.</Text>
      ) : (
        <>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Cluster ID</Th>
                <Th>Org ID</Th>
                <Th>Company Name </Th>
                <Th>Plan</Th>
                <Th>Apiary</Th>
                {/*TODO Make this invisible once done with testing*/}
                <Th>Email</Th>
              </Tr>
            </Thead>
            <Tbody>
              {hivesRequest.isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <Tr key={index}>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <Td key={index}>
                          <Skeleton height="20px" />
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
              {!!legacyHivesTableData &&
                !!filteredHives &&
                filteredHives.map((cluster) => (
                  <Tr
                    key={cluster.org_id + Math.random()}
                    _hover={{ backgroundColor: 'gray.100' }}
                  >
                    {/*  Cluster Status*/}
                    <Td>
                      <HStack>
                        <DataTag
                          backgroundColor={getColorForStatus(
                            cluster.hive_info?.hive_status
                          )}
                          value={cluster.hive_info?.hive_status}
                          hasCopy={false}
                        >
                          {cluster.hive_info?.hive_status}
                        </DataTag>
                      </HStack>
                    </Td>
                    {/*  Cluster ID*/}
                    <Td>
                      {cluster?.hive_info?.cluster_id ? (
                        <DataTag
                          onClickBody={() =>
                            routeToClusterDetails(
                              cluster.org_id,
                              cluster.hive_info?.cluster_id
                            )
                          }
                          hasCopy
                          value={cluster.hive_info?.cluster_id}
                          maxWidthPx={200}
                        >
                          {cluster.hive_info?.cluster_id}
                        </DataTag>
                      ) : (
                        <DataTag>N / A</DataTag>
                      )}
                    </Td>
                    {/*  Organization ID */}
                    <Td>
                      <DataTag
                        hasCopy
                        onClickBody={() =>
                          routeToOrganizationDetails(cluster.org_id)
                        }
                        value={cluster.org_id}
                      >
                        {cluster.org_id}
                      </DataTag>
                    </Td>
                    {/*  Company Name*/}
                    <Td>
                      <DataTag hasCopy value={cluster.company_name}>
                        {cluster.company_name}
                      </DataTag>
                    </Td>
                    {/*  Cluster Plan*/}
                    <Td>
                      <Text fontSize={'md'}> {cluster.hive_info?.plan} </Text>
                    </Td>
                    {/*  Apiary */}
                    <Td>{cluster.hive_info?.apiary}</Td>
                    {/*  Email Address*/}
                    <Td>{cluster.email?.email_address}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <Box
            width={'100%'}
            display="flex"
            justifyContent="space-between"
            mt={4}
          >
            <Button onClick={handlePreviousPage} isDisabled={page === 1}>
              Previous
            </Button>
            <Box>
              Page {hivesRequest.data?.current_page} of{' '}
              {hivesRequest.data?.total_pages}
            </Box>
            <Button
              onClick={handleNextPage}
              isDisabled={!hivesRequest.data?.has_next_page}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </PageLayout>
  )
}

export type ClusterStateProps = {
  clusterState: string | undefined
}

export function ClusterStateIcon({
  /**
   * The revision from which we need to interpret states.
   */
  clusterState,
}: PropsWithChildren<ClusterStateProps>) {
  return (
    <Box paddingY={'0.25rem'}>
      {clusterState === 'PENDING' && <CircleMinus size={'1rem'} color="gray" />}
      {clusterState === 'RUNNING' && <Check size={'1rem'} color="green" />}
      {clusterState === 'DELETING' && (
        <RotateCcw size={'1rem'} color="red" className={styles.rotatingCcw} />
      )}
      {clusterState === undefined && (
        <CircleAlert size={'1rem'} color="orange" />
      )}
    </Box>
  )
}
