import { VStack } from '@chakra-ui/react'
import React from 'react'
import { EditableSection } from '@/features/ClusterConfigs/components/EditableSection/EditableSection'

type Section = {
  id: string
  title: string
  value: string
  isReadOnly?: boolean
}

type EditableSectionsGroupProps = {
  sections: Section[]
  onChange: (updatedData: Record<string, string>) => void
  editorOptions?: object
}

export function EditableSectionsGroup({
  sections,
  onChange,
  editorOptions = {},
}: EditableSectionsGroupProps) {
  const [sectionData, setSectionData] = React.useState<Record<string, string>>(
    Object.fromEntries(sections.map((section) => [section.id, section.value]))
  )

  // Sync internal state with props whenever `sections` changes
  React.useEffect(() => {
    setSectionData(
      Object.fromEntries(sections.map((section) => [section.id, section.value]))
    )
  }, [sections])

  const handleSectionChange = (id: string, newValue: string) => {
    setSectionData((prev) => {
      const updatedData = { ...prev, [id]: newValue }
      onChange(updatedData) // Pass the updated data back to the parent
      return updatedData
    })
  }

  return (
    <VStack width="100%" alignItems="stretch" gap={6}>
      {sections.map((section) => (
        <EditableSection
          key={section.id}
          title={section.title}
          value={sectionData[section.id]}
          onChange={(newValue) => handleSectionChange(section.id, newValue)}
          editorOptions={editorOptions}
          isReadOnly={section.isReadOnly ?? false}
        />
      ))}
    </VStack>
  )
}
