import {
  Box,
  Skeleton,
  Tag,
  TagProps,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Icon } from '@chakra-ui/icons'
import { CheckIcon, CopyIcon } from 'lucide-react'

type DataTagProps = {
  value?: string
  externalLink?: string
  maxWidthPx?: number
  onClickBody?: () => void
  hasCopy?: boolean
} & TagProps

export function DataTag({
  /**
   * The value that the tag represents. The visual value and this value that is used in the potential copy action are independent.
   */
  value,
  /**
   * Link to route to external resoruces from this tag. Only affects the clickable text portion of the tag so the copy button doesn't get overrriden.
   *
   */
  externalLink,
  /**
   * Max width in px that can optionally pass to shorten the visual value of the tag.
   */
  maxWidthPx,
  /**
   * Only works if no external link is given, don't pass in both. Function to be called when the text body of the tag is clicked.
   */
  onClickBody,
  /**
   * By default shows a copy button, pass false to remove it.
   */
  hasCopy = true,
  children,
  ...tag
}: PropsWithChildren<DataTagProps>) {
  const { hasCopied, onCopy } = useClipboard(value || '')

  const [loading, setLoading] = useState(
    value === undefined || value === null || value === ''
  )
  const isEmpty = useMemo(
    () => value === undefined || value === null || value === '',
    [value]
  )
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  useEffect(() => {
    if (externalLink) {
      setLoading(false)
    }
    if (!loading) return
    const timeout = setTimeout(() => setLoading(false), 1500)
    return () => clearTimeout(timeout)
  }, [loading])

  function handleCopy() {
    onCopy()
    setTooltipIsOpen(true)
    const timeout = setTimeout(() => setTooltipIsOpen(false), 1500)
    return () => clearTimeout(timeout)
  }

  function valueIsClickable() {
    return !!externalLink || onClickBody
  }

  function handleRouting() {
    if (externalLink) {
      window.open(externalLink, '_blank', 'noopener,noreferrer')
    } else if (onClickBody) {
      onClickBody()
    }
  }

  const isNotExternalLink = isEmpty && !externalLink

  const style = maxWidthPx
    ? {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: `${maxWidthPx}px`,
      }
    : {}

  const textHover = valueIsClickable()
    ? {
        cursor: 'pointer',
        textDecoration: 'underline',
      }
    : {
        cursor: 'default',
      }

  const tagColorScheme = useMemo(() => {
    if (!loading && isNotExternalLink) {
      return 'red'
    }
    if (valueIsClickable()) {
      return 'blue'
    }
    return tag.colorScheme
  }, [loading])

  return (
    <Skeleton isLoaded={!loading}>
      <Tag
        {...tag}
        style={{
          cursor: 'default',
        }}
        _hover={{
          cursor: 'pointer',
        }}
        colorScheme={tagColorScheme}
      >
        {loading ? (
          <span>Loading...</span>
        ) : isEmpty && isNotExternalLink ? (
          <span>N / A</span>
        ) : (
          <Box
            _hover={textHover}
            onClick={handleRouting}
            style={style}
            className="data-tag-text"
          >
            {children}
          </Box>
        )}

        {hasCopy && !loading && !isEmpty ? (
          <Tooltip
            hasArrow
            label="Copied"
            borderRadius={4}
            colorScheme="gray"
            isOpen={tooltipIsOpen}
          >
            <Icon
              ml={2}
              as={hasCopied ? CheckIcon : CopyIcon}
              onClick={handleCopy}
              color={hasCopied ? 'icon.icon-success' : 'inherit'}
              _hover={{
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        ) : null}
      </Tag>
    </Skeleton>
  )
}
