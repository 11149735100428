import EmptyStateImage from '@/assets/imgs/api-error.svg'
import { Box, Button, Flex, Icon, Image, Text, VStack } from '@chakra-ui/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { RefreshCwIcon } from 'lucide-react'
import type React from 'react'

type APIEmptyStateProps = {
  apiResource: UseQueryResult
  errorTitle?: React.ReactNode
  errorDescription?: React.ReactNode
  buttonText?: string
}

export function ApiErrorState({
  apiResource,
  errorTitle = 'An Error Occurred!',
  errorDescription,
  buttonText = 'Reload',
}: APIEmptyStateProps) {
  return (
    <>
      {apiResource.error ? (
        <Box
          border="2px solid"
          borderColor="red.400"
          borderRadius="6"
          padding="8"
          w="100%"
          backgroundColor="surface.0"
        >
          <Flex justifyContent="center" gap="8" alignItems="center">
            <Image src={EmptyStateImage} alt="Empty state image" />
            <VStack gap="4" alignItems="start" mt={3}>
              <Box>
                <Text fontWeight={500} fontSize="1rem" mb={0}>
                  {errorTitle}
                </Text>
                {errorDescription ? (
                  <Text mb={0}>{errorDescription}</Text>
                ) : null}
              </Box>
              <Button
                variant={'secondary'}
                isLoading={apiResource.isLoading}
                loadingText={'Loading...'}
                leftIcon={<Icon as={RefreshCwIcon} />}
                onClick={() => apiResource.refetch()}
              >
                {buttonText}
              </Button>
            </VStack>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}
