import * as React from 'react'
import {
  Button,
  Card,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'

import { timeAgo } from '@/helpers/timeFormatter'
import { createRoute, Link, useNavigate } from '@tanstack/react-router'
import { clusterConfigsRootRoute } from '@/features/ClusterConfigs/pages/ClusterConfigsPage/ClusterConfigsPage'
import {
  useGetCluster,
  useGetClusterRevisions,
} from '@/generated/http-clients/v3ApiComponents'
import { RevisionState } from '@/features/ClusterConfigs/components/States/RevisionState'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { clusterConfigDraftRoute } from '../ClusterConfigDraft/ClusterConfigDraft'
import { PlusIcon } from 'lucide-react'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import {
  ClusterHead,
  LatestClusterRevision,
  Revision,
} from '@/generated/http-clients/v3ApiSchemas'
import { ClusterConfigCombinedStates } from '../../components/States/ClusterConfigCombinedStates'
import capitalize from 'lodash/capitalize'
import styles from './clusterConfigsRevisions.module.css'
import { clusterConfigTableRoute } from '../ClusterConfigTable/ClusterConfigTable'
import { clusterConfigDiffRoute } from '@/features/ClusterConfigs/pages/ClusterConfigDiff/ClusterRevisionDetails'

export const clusterConfigRevisionsRoute = createRoute({
  getParentRoute: () => clusterConfigsRootRoute,
  path: '/',
  component: () => <ClusterConfigRevisions />,
})

function ClusterConfigRevisions() {
  const nav = useNavigate()
  const pathParams = clusterConfigRevisionsRoute.useParams()
  const cluster = useGetCluster({ pathParams })

  const clusterRevisions = useGetClusterRevisions({
    pathParams,
  })

  const activeRevision = clusterRevisions.data?.find((revision) => {
    return revision.approval_state.state === 'APPLIED'
  })

  const [selectedRows, setSelectedRows] = React.useState<string[]>([])
  const breadCrumbs = [
    { name: 'Hives & Configs', path: clusterConfigTableRoute.to },
    {
      name: pathParams.clusterId,
      path: '',
    },
  ]

  function onChange(id: string) {
    if (selectedRows.includes(id)) {
      setSelectedRows((rows) => rows.filter((row) => row !== id))
    } else {
      setSelectedRows((rows) => [...rows, id])
    }
  }

  function handleCompare() {
    nav({
      to: `/clusters/$orgId/$clusterId/revision/compare/$revisionId0/$revisionId1`,
      params: {
        clusterId: pathParams.clusterId,
        orgId: pathParams.orgId,
        revisionId0: selectedRows[0],
        revisionId1: selectedRows[1],
      },
    })
  }

  // sort revisions by revision ID descending (latest revision on top)
  function sortRevisions(revisionA: Revision, revisionB: Revision) {
    return (
      Number.parseInt(revisionB.revision_id) -
      Number.parseInt(revisionA.revision_id)
    )
  }

  function combineIntoLatestClusterRevision(
    revision: Revision,
    { disabled, hive_state, rollout, system, template_id }: ClusterHead
  ): LatestClusterRevision {
    return {
      revision: {
        hive_state,
        approval_state: revision.approval_state,
        rollout,
        template_id,
        hive_id: pathParams.clusterId,
        hive_name: system?.global?.name || '',
        org_id: pathParams.orgId,
        apiary_id: system?.global?.location || '',
      },
      disabled,
    }
  }

  function revisionActionMetadata(revision: Revision) {
    let time = revision.updated_at
      ? timeAgo(revision.updated_at || '')
      : undefined
    const isSubmitted = revision.approval_state.state === 'SUBMITTED'
    const by = `by ${isSubmitted ? revision.created_by : revision.approved_by}`
    // only in the submitted case: if updated_at is undefined but created_at is, use that field
    if (isSubmitted && !time && revision.created_at) {
      time = timeAgo(revision.created_at)
    }

    return `${time ? `${time} ` : ''}${by}`
  }

  return (
    <PageLayout
      title={cluster.data?.system?.global?.name}
      description={
        <Text m={0} color="text.text-subtle">
          You can create a new draft revision for this hive from scratch, or
          open an existing revision and create a new one based on it.
        </Text>
      }
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
      action={
        <Flex gap={'4'}>
          {(clusterRevisions.data?.length ?? 0) > 1 ? (
            <Tooltip
              label={
                selectedRows.length !== 2 ? 'Select 2 revisions to compare' : ''
              }
            >
              <Button
                variant={'outline'}
                onClick={handleCompare}
                isDisabled={selectedRows.length !== 2}
              >
                Compare changes
              </Button>
            </Tooltip>
          ) : null}
          <Link
            to={clusterConfigDraftRoute.to}
            params={{
              ...pathParams,
              baseRevisionId: activeRevision?.revision_id ?? '',
            }}
          >
            <Button variant="primary" leftIcon={<Icon as={PlusIcon} ml={-1} />}>
              New draft revision
            </Button>
          </Link>
        </Flex>
      }
    >
      <Card w="100%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th width="32px" paddingRight="0" />
              <Th width="32px" paddingRight="0" />
              <Th>Revision ID</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clusterRevisions.isLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <Tr key={index}>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Td key={index}>
                        <Skeleton height="20px" />
                      </Td>
                    ))}
                  </Tr>
                ))
              : null}

            {clusterRevisions.data?.sort(sortRevisions).map((revision) => {
              return (
                <Tr
                  key={revision.revision_id}
                  className={styles.trAlignContentTop}
                  _hover={{ backgroundColor: 'gray.100' }}
                >
                  <Td className={styles.trAlignContentTopTdCheckbox}>
                    <Checkbox
                      onChange={() => onChange(revision.revision_id)}
                      isDisabled={
                        selectedRows.length == 2 &&
                        !selectedRows.includes(revision.revision_id)
                      }
                      isChecked={selectedRows.includes(revision.revision_id)}
                    />
                  </Td>
                  <Td className={styles.trAlignContentTopTdIcon}>
                    {/* if it's the active revision, we can display a better (combined) state for that one */}
                    {revision.revision_id ===
                    cluster.data?.rollout?.revision_id ? (
                      <ClusterConfigCombinedStates
                        revision={combineIntoLatestClusterRevision(
                          revision,
                          cluster.data
                        )}
                      />
                    ) : (
                      <RevisionState approvalState={revision.approval_state} />
                    )}
                  </Td>

                  <Td
                    className={styles.trAlignContentTopTdRevision}
                    onClick={() => {
                      nav({
                        to: clusterConfigDiffRoute.to,
                        params: {
                          ...pathParams,
                          revisionId: revision.revision_id,
                        },
                      })
                    }}
                  >
                    <VStack gap={1} alignItems={'start'}>
                      <HStack>
                        <Link
                          to={clusterConfigDiffRoute.to}
                          params={{
                            ...pathParams,
                            revisionId: revision.revision_id,
                          }}
                        >
                          <Text
                            variant="P2"
                            fontWeight={700}
                            _hover={{
                              textDecoration: 'underline',
                            }}
                          >
                            {revision.revision_id}
                          </Text>
                        </Link>
                        {revision.revision_id ===
                        cluster.data?.rollout?.revision_id ? (
                          <Tag colorScheme={'green'}>active</Tag>
                        ) : null}
                      </HStack>
                      <Text fontSize="sm" color="gray">
                        {capitalize(revision.approval_state.state)}{' '}
                        {revisionActionMetadata(revision)}
                      </Text>
                    </VStack>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Card>
    </PageLayout>
  )
}
